<template>
    <div>
        <div id="mainLoading" v-show="this.loading == true" class="background"  style="text-align: center;">
            <div class="text-center" style="margin-top:25vh">
                <img width="250px"  :src="require('/public/BirdHuntWhiteLogoCropped.png')">
                <v-progress-linear indeterminate color=" white " style="width: 150px; margin-left: auto; margin-right:auto; margin-top: 1.5rem;" height=3 background-opacity="0"></v-progress-linear>
            </div>
        </div>
        <div id="mobileBlock"  v-show="this.loading == false"  class="background d-md-none"  style="text-align: center;">
            <div class="text-center" style="margin-top:25vh">
                <img width="250px"  :src="require('/public/BirdHuntWhiteLogoCropped.png')">
                <h5 style="color: white; margin-top:5vh"><b>InstaHunt is Desktop Only</b></h5>
                <div  class="d-flex justify-content-center">
                <div class="col-10" >
                <p style="color: white;">Sorry for the inconvenience, <b>InstaHunt</b> can only be used on desktop for now. But in the mean time try out <a href="https://birdhunt.co" target="_blank" style="text-decoration: underline; color: white;"><b>BirdHunt</b></a> for finding tweets based on location.</p>
                </div>
                </div>
            </div>
        </div>
        <!-- Overlay which dims the entire screen -->
            <v-overlay
                opacity="0.66"
                color="#000000"
                :value="dialog"
            ></v-overlay>

            <!-- this is the dialog box itself the customDialog class gives you the ability to style it using custom css ensure the stlye tag isn't scoped -->
            <v-dialog
                v-model="dialog"
                persistent
                content-class="customDialog"
                width="70%"

                max-width="1000px"
                transition="dialog-bottom-transition"
            >
                <!-- no shadow and no rounded corners on the card -->
                <v-card class="rounded-0" elavation="0" dark color="#0D1117">
                    <!-- white "flat look" vuetify tool bar -->
                    <v-toolbar class="elevation-0" dark color="#0D1117">
                        <!-- this is used as a spacer for the title -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <!-- title of dialog box -->
                        <v-toolbar-title
                            ><strong style="color: #e10367;"
                                >Get Instagram Places</strong
                            ></v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <!-- close button and icon -->
                            <v-btn icon @click="close()">
                                <v-icon style="color: #e10367;">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>

                        <div class="col-1 hidden-mobile padding-mobile" />
                    </v-toolbar>

                    <!-- the coloured divider between the header and the content -->
                    <v-divider class="mt-0 mb-3" style="color: #e10367;"></v-divider>

                    <div class="row m-0 p-2">
                        <!-- spacer for the dialog content -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <div class="col-12 col-md-10 text-center padding-mobile">
                            <p style="text-align: left; color: rgb(204, 218, 231);">
                                <b>InstaHunt requires you to have a Instagram account -</b> You will need to be logged in to Instagram on your browser and have cookies accepted.
                            </p>
                            <p style="text-align: left; color: rgb(167, 181, 196)">
                                Step 1:<br><b>Click the "Get Instagram Place Data" button -</b> After clicking the button, if you are logged out, a new tab will open where you'll be re-directed to the Instagram log in page. Please log in and accept cookies. You'll then be re-directed to your "Place Data".
                            </p>

                            <v-btn 
                            @click="openData()" 
                            target="_blank"
                            color="#e10367"
                            outlined
                            elevation="0"
                            class="col-12 no-padding"
                            >
                            <span>Get Instagram Place Data</span>
                            </v-btn>
                            <a id="link" target="_blank" :href="this.generatedLink" download hidden></a>

                            <v-divider class="my-6"></v-divider>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">Final Step:<br><b>Copy and paste the "Place Data" -</b> Copy the entirety of your place data and paste it into the box below.</p>
                            <v-textarea
                            outlined
                            color="#e10367"
                            elevation=0
                            placeholder="Paste your Instagram 'Place Data' here"
                            v-model="pasteData"
                            ></v-textarea>

                            <v-btn 
                            @click="submitData()" 
                            target="_blank"
                            color="#e10367"
                            elevation="0"
                            class="col-12 mb-6 no-padding"
                            :disabled="!jsonValidator"
                            :loading="loadingData"
                            >
                            <span style="color: white">Map Your Instagram Places</span>
                            </v-btn>

                            <v-alert dense class="col-12" v-if="errorMessageSubmit" type="error">Error - The data submitted is in the wrong format</v-alert>

                        </div>
                    </div>
                </v-card>
            </v-dialog>
            <div id="topContainer">
            <v-btn id="submissionBtn" color="#e10367" dark elevation="0" style="text-transform: none; letter-spacing: normal; font-weight: 600;" class="no-padding" @click="searchPostsClicked()"><span><span v-if="!dataPresent">Find Places <v-icon class="ml-2">mdi-magnify</v-icon></span><span v-if="dataPresent">Add More Places <v-icon class="ml-2">mdi-plus</v-icon></span></span></v-btn>
            <v-btn id="clearBtn" v-if="dataPresent" dark color="#DC3545" elevation="0" style="text-transform: none; letter-spacing: normal; font-weight: 600;" class="no-padding" @click="clearPlaces()"><span>Clear Map<v-icon class="ml-2">mdi-backspace</v-icon></span></v-btn>
            <v-alert dense id="alert" class="col-7 col-xl-8" v-if="errorMessage" type="error"><span v-if="!doubleCheckError"><span class="hidden-mobile">No Location Selected! Please click on the map to s</span><span class="visible-mobile">S</span>elect a location</span><span v-if="doubleCheckError">Same Location Selected! Please click on the map to select a location</span></v-alert>
            </div>
        <div id="map">
            <mapbox
                access-token="pk.eyJ1IjoiaW5zdGFodW50IiwiYSI6ImNremxmeWpkbzA5OXYzM2xhMTA5N2I2bW4ifQ.B00vi6IhVlQtQL8-jIvgbg"
                :map-options="{
                    style: 'mapbox://styles/instahunt/ckzlfzd8r00ij15pcdhof7wsi',
                    center: ['0', '7'],
                    zoom: 2,
                }"
                :attribution-control="{
                    show: true,
                    position:'bottom-right',
                }"
                :geocoder-control="{
                    show: true,
                    position: 'top-right'
                }"
                :fullscreen-control="{
                    show: true,
                    position: 'bottom-right'
                }"
                @map-init="initialiseMap"
                @map-load="loaded"
                @map-click="mapClicked"
                @map-dblclick="doubleClicked"
                @map-mouseenter:place-markers="mouseEntered"
                @map-mouseleave:place-markers="mouseLeft"
                @map-click:place-markers="mouseClicked"

            />
        </div>

    </div>
</template>

<script>

import Mapbox from "mapbox-gl-vue";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import PopupContent from './PopupContent.vue';

export default {

    props: {
        mapStyle: String,
        disUnit: String,
    },
    
    name: 'mapBirdHunt',

    components:{
        Mapbox
    },

    data(){
        return {
            // Legacy Variables //
            slider: null,
            url: null,
            showOptions: false,
            searchQuery: '',
            filter: null,
            question: false,
            minLikes: null,
            minRetweets:null,
            unit: 'KM',
            disMult: 1,

            // Map Variables //
            lat: null,
            lon: null,
            loading: true,
            marker: null,
            popup: null,
            dialog: false,
            dbl: false,
            clicked: false,
            errorMessage: false,
            featureList: [],
            loadingData: false, 
            markers: [],
            map: null,

            // New Variables //
            pasteData: null,
            step: 'login',
            dataObject: null,
            markerClicked: false,
            dataPresent: false,
            lastLat: null,
            lastLon: null,
            doubleCheckError: false,
            errorMessageSubmit: false, 
        }
    },

    methods: {
        // Map Functions //
        loaded: function(map) {
            this.loading = false;
            map.on('idle',function(){
                map.resize()
            })
        },
        initialiseMap: function(map) {
            this.map = map;
            const geocoder = new MapboxGeocoder({
                accessToken: "pk.eyJ1IjoiaW5zdGFodW50IiwiYSI6ImNremxmeWpkbzA5OXYzM2xhMTA5N2I2bW4ifQ.B00vi6IhVlQtQL8-jIvgbg",
            })

            map.addControl(
                geocoder
            );

            geocoder.on('result', (event) => {
                if(this.marker){
                    this.marker.remove();  
                }
                const el = document.createElement('div');
                el.className = 'marker';
                var coordinates = event.result.geometry.coordinates;
                this.marker = new mapboxgl.Marker(el);
                this.marker.setLngLat(coordinates).addTo(this.map);

                this.clicked = true;
                this.errorMessage = false;
            });
        },
        mapClicked: function(map, e){
            this.dbl = false;
            this.markerClicked = false;

            setTimeout(() => {
            if (!this.dbl && !this.markerClicked) {
                if(this.marker){
                    this.marker.remove();  
                }                
                const el = document.createElement('div');
                el.className = 'marker';
                var coordinates = e.lngLat;
                this.marker = new mapboxgl.Marker(
                    { element: el, anchor: 'bottom'}
                );
                this.marker.setLngLat(coordinates).addTo(this.map);

                this.clicked = true;
                this.errorMessage = false;
                this.doubleCheckError = false;
                if(this.popup){
                this.popup.remove();
                this.popup = null;
                }
            }
            }, 500)
        },
        doubleClicked: function(){
            this.dbl = true;
        },
    
        // New Functions //
        searchPostsClicked: function(){
            if(this.clicked){
                this.lat = this.marker._lngLat.lat;
                this.lon = this.marker._lngLat.lng;
                if(this.lat == this.lastLat && this.lon == this.lastLon && this.dataPresent){
                    this.errorMessage = true;
                    this.doubleCheckError = true;
                } else {
                    this.dialog = true;
                }
            } else {
                this.errorMessage = true;
            }

        },
        goToInstagramLogin(){
            let url = 'https://www.instagram.com/accounts/login/';
            window.open(url, '_blank');
        },
        openData(){
            document.getElementById('link').click();
            //window.open(this.generatedLink, '_system');
        },
        submitData(){
            this.errorMessageSubmit = false;
            this.dataObject = JSON.parse(this.pasteData);
            if(this.dataObject.venues){
                let visualisationData = {};
                for (var i = 0; i < this.dataObject.venues.length; i++) {
                    let dataObject = this.dataObject.venues[i];
                    if(!this.markers[dataObject.external_id]){
                        visualisationData = {
                        // extract required data from the location objects
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [dataObject.lng, dataObject.lat]
                            },
                            properties: {
                                name: dataObject.name,
                                address: dataObject.address,
                                id: dataObject.external_id,
                                id_source: dataObject.external_id_source,
                            }
                        };
                        this.featureList.push(visualisationData);
                    }
                }
                this.lastLat = this.lat;
                this.lastLon = this.lon;
                this.dataPresent = true;
                this.loadedCheck = true;
                this.addMarkersToMap();
                this.loadingData = false;
                this.pasteData = null;
                this.close();
            } else {
                this.errorMessageSubmit = true;
            }
        },
        addMarkersToMap: function(){
            if(this.map.getSource('places')){
                var data = {
                    type: "FeatureCollection",
                    features: this.featureList
                }
                this.map.getSource('places').setData(data);
            } else {
                this.map.addSource("places", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: this.featureList
                },
            });
            }

            if(this.map.getLayer('place-markers')){
                console.log('exists');
            } else {
                this.map.addLayer({
                id: "place-markers",
                type: "circle",
                source: "places",
                'paint': {
                    'circle-color': 'transparent',
                    'circle-radius': 40,
                    'circle-stroke-color': 'transparent'
                }
                });
            }

            function updateMarkers(map, markers) {
                if(map.getSource('places')){
                const places = map.querySourceFeatures('places', 'place-markers');
                const features = places;
                for (const feature of features) {
                    const coords = feature.geometry.coordinates;
                    const props = feature.properties;
                    const id = props.id;
                    console.log(markers[id]);
                    let marker = markers[id];
                    if (!marker) {
                        const el = document.createElement('div');
                        el.className = 'marker-place';
                        marker = markers[id] = new mapboxgl.Marker({element: el}).setLngLat(coords);
                        marker.addTo(map);
                    }
                }
                return markers;
                }
            }

            this.map.on('render', () => {
                if(this.map.getSource('places')){
                    if (!this.map.isSourceLoaded('places')) return;
                    this.markers = updateMarkers(this.map, this.markers);
                } else {
                    return
                }
            });

        },
        close(){
            this.dialog = false;
            this.pasteData = null;
            this.lat = null;
            this.lon = null;
            this.errorMessageSubmit = false;
        },
        clearPlaces(){
            this.dataPresent = false;
            this.map.removeLayer('place-markers');
            let markersToGo = document.querySelectorAll('.marker-place');
            console.log(markersToGo);
            for (var i = 0; i < markersToGo.length; i++) {
                markersToGo[i].parentNode.removeChild(markersToGo[i]) //or images[i].remove()
            }
            this.markers = [];
            this.featureList = [];
            if(this.popup){
                this.popup.remove();
                this.popup = null;
            }
            this.errorMessage = false;
            this.doubleCheckError = false;
            //this.clearMarkersFromMap();
        },

        mouseEntered(map, e) {
            if(this.popup){
                this.popup.remove();
                this.popup = null;
            }
            map.getCanvas().style.cursor = 'pointer'
            var coordinates = e.features[0].geometry.coordinates.slice();
            //var description = e.features[0].properties.description;
            //var postcode = e.features[0].properties.postcode;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] +=
                e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            this.popup = new mapboxgl.Popup({ offset: [0, -30], className: "popupStyle", maxWidth: '300px', closeButton: false }).setLngLat(coordinates).setHTML('<div id="vue-popup-content"></div>').addTo(map);
            new PopupContent({
                        propsData: { feature: e},
                    }).$mount('#vue-popup-content')
            this.popup._update()
        },
        mouseLeft() {
            this.map.getCanvas().style.cursor = '';
        },
        mouseClicked() {
            this.markerClicked = true;
            //let url = 'https://www.instagram.com/explore/locations/' + e.features[0].properties.id;
            //window.open(url, '_blank');
        },
        toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
}
    },
    computed: {
        // New Functions //
        generatedLink(){
            return 'https://www.instagram.com/location_search/?latitude=' + this.lat + '+&longitude=' + this.lon;
        },
        jsonValidator(){
            if(this.pasteData){
                try {
                    JSON.parse(this.pasteData);
                } catch (e) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        }
    }, 
    watch : {
        // Map Styling Functionality //
        mapStyle(){
            if(this.mapStyle == 'satellite'){
                this.map.setStyle('mapbox://styles/instahunt/ckzlfzd8r00ij15pcdhof7wsi?optimize=true');
                
            } else {
                this.map.setStyle('mapbox://styles/instahunt/ckzlg8nqv002015jv1zo4o6yu?optimize=true');
            }
            this.$emit('styleChanged', true);
        },
        dataPresent(){
            let data = this.dataPresent;
            this.$emit('dataPresent', data);
        }
    }
}
</script>

<style>
#mainLoading { z-index: 4; position: absolute; width: 100%;  height: 100% !important; overflow-y: hidden !important;}
#map { z-index: 0 !important; position: absolute !important; width: 100% !important; height: 100% !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}
#mobileBlock { z-index: 4; position: fixed; width: 100%;  height: 100% !important; overflow-y: hidden !important;}
.background{
    background: #e10367;
}
.marker {
  background-image: url('/public/BirdHuntPin.png');
  background-size: contain;
  width: 30px;
    height: 50px;
}
.marker-place {
  background-image: url('/public/MarkerPlace.png');
  background-size: contain;
  width: 30px;
    height: 50px;
}
.buttonSpacer {
    margin-top: 20vh;
}
#submissionBtn{width: 20% !important; margin-left: 10px; max-width: 300px; min-width: 240px;} 
#clearBtn{width: 10% !important; margin-left: 10px; max-width: 180px; min-width: 160px;}
#alert{margin-left: 10px; height: 36px; margin-bottom: 0px;}
#topContainer{z-index: 3 !important; position: absolute !important; margin-top: 10px; display: flex;  width: calc(100% - (10px + 240px + 10px));}

@media (min-width: 767px) and (max-width: 1140px) {
    #alert{z-index: 3 !important; position: absolute !important; margin-bottom: 30px; width: calc(100% - 550px); margin-top:10px; margin-right: calc(10px + 240px + 10px); margin-left: calc(280px + 10px); height: 36px;}
}

@media (max-width: 767px) {
        .visible-mobile{
            display: inline;
        }
        .hidden-mobile {
          display: none;
        }
        #submissionBtn{z-index: 3 !important; position: fixed !important; bottom: 0px; width: 100vw !important; margin-bottom: 10px; margin-left: 10px; max-width: calc(100vw - 20px);}
        .customDialog.v-dialog--fullscreen {
            width: 100%;
            right: 0px;
            left: 0px;
            border-radius: 0px;
        }
        #map{ z-index: 0 !important; position: fixed !important; width: 100vw !important; height: 100vh !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}
        #alert{z-index: 3 !important; position: fixed !important; bottom: 30px; width: 100vw !important; max-width: calc(100vw - 20px); margin-top:unset; margin-right: 10px; margin-left: 10px; height: unset;}
        .noScroll{
            position: fixed;
        }

      }
@media (min-width: 767px) {
    .visible-mobile{
        display: none;
    }
    
}

</style>