<template>
  <v-app>
    <v-app-bar
      app
      color="#0D1218"
      elevation=0
      style="border-bottom: 2px solid #e10367 !important;;"
    >
      <div class="d-flex align-center">
        <v-img
          alt="InstaHunt Logo"
          contain
          :src="require('/public/logo1.png')"
          transition="scale-transition"
          width="150"
          dense
        />
        <a href="https://www.huntintel.io/" style="color: #44d62c; text-decoration: underline;" class="hidden-mobile">
          <img
            alt="Hunt Intelligence Logo"
            :src="require('/public/HuntLogoClean.png')"
            style="max-width: 65px !important; padding-bottom: 0.36rem;"
          />
        </a>
      </div>

      <v-spacer class="hidden-mobile"></v-spacer>

      <v-btn
        text
        color="#e10367"
        @click="help = true"
        class="hidden-mobile"
        dark
        icon
        style="text-transform: none; letter-spacing: normal; font-weight: 600;"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <map-bird-hunt @styleChanged="help=false" @dataPresent="dataSwitch" :mapStyle="mapStyle" :disUnit="disUnit"/>
            <v-overlay
                opacity="0.66"
                color="#000000"
                :value="help"
            ></v-overlay>

            <!-- this is the dialog box itself the customDialog class gives you the ability to style it using custom css ensure the stlye tag isn't scoped -->
            <v-dialog
                v-model="help"
                content-class="customDialog noScroll"
                fullscreen
                transition="dialog-bottom-transition"
            >
                <!-- no shadow and no rounded corners on the card -->
                <v-card class="rounded-0" elavation="0" dark color="#0D1117">
                    <!-- white "flat look" vuetify tool bar -->
                    <v-toolbar class="elevation-0" dark color="#0D1117">
                        <!-- this is used as a spacer for the title -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <!-- title of dialog box -->
                        <v-toolbar-title
                            ><strong style="color: #e10367;"
                                >Settings</strong
                            ></v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <!-- close button and icon -->
                            <v-btn icon @click="help=false">
                                <v-icon style="color: #e10367;">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>

                        <div class="col-1 hidden-mobile padding-mobile" />
                    </v-toolbar>

                    <!-- the coloured divider between the header and the content -->
                    <v-divider class="mt-0 mb-3" style="color: #e10367;"></v-divider>

                    <div class="row m-0 p-2">
                        <!-- spacer for the dialog content -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <div class="col-12 col-md-10 text-center padding-mobile">
                          <v-expansion-panels style="border: 1px solid #44444410; background-color: #0D1117;" flat accordion dark color="#0D1117">
                        <v-expansion-panel style="border: 1px solid #44444410; background-color: #0D1117;" flat>
                          <v-expansion-panel-header style="color: rgb(204, 218, 231);"><b>Map Settings</b></v-expansion-panel-header>
                          <v-expansion-panel-content>
                           <p style="text-align: left; color: rgb(167, 181, 196)"><b>🗺️ Choose your map style</b> - Satellite is useful for precisely placing your marker on the map</p>
                            <v-row v-if="!this.dataPresent" class="my-6" justify="space-around">
                                  <v-chip-group  v-model="mapStyle" mandatory color="#e10367">
                                    <v-chip value="dark">
                                      Dark
                                    </v-chip>
                                    <v-chip value="satellite">
                                      Satellite
                                    </v-chip>
                                  </v-chip-group>
                            </v-row>
                            <v-row v-if="this.dataPresent" class="my-6">
                              <v-chip class="col-10 ml-2">
                                  Only selectable when the map is cleared
                              </v-chip>
                            </v-row>
                            <!-- <p style="text-align: left; color: #444444"><b>📍 Choose your unit of distance</b> - Select the unit your are most familiar with</p>
                            <v-row class="my-6" justify="space-around">
                                  <v-chip-group v-model="disUnit" mandatory color="#e10367">
                                    <v-chip value="kilometers">
                                      KM
                                    </v-chip>
                                    <v-chip value="miles">
                                      Miles
                                    </v-chip>
                                  </v-chip-group>
                            </v-row> -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel style="border: 1px solid #44444410; background-color: #0D1117;" flat>
                          <v-expansion-panel-header style="color: rgb(204, 218, 231);"><b>How to use InstaHunt</b></v-expansion-panel-header>
                          <v-expansion-panel-content>
                           <p style="text-align: left;  color: rgb(167, 181, 196)">📷<b> InstaHunt shows you Instagram places and posts</b> surrounding the chosen geographic location</p>
                           <p style="text-align: left; color: rgb(167, 181, 196)"><b>Note: </b>There is a limit to how many places you can add at any one time. Currently Instagram have this set to 29 places. Due to this, to get a comprehensive view opf an area I recommended that you do multiple searches, by clicking the "Add More Places" button where you change your desired location slighlty and re-copy the data.</p>

                            <img class="mt-12 mb-6 col-12" style="padding: 0px; border: 2px solid #e10367;" :src="require('/public/Step1.png')"/>
                            <p class="my-6" style="text-align: left;  color: rgb(167, 181, 196)"><b>1. Choose the location</b> that you want to find instagram places near. This is done by clicking the map to add a marker at your desired location. Then click the "Find Places <v-icon>mdi-magnify</v-icon>" button.</p>
                            <img class="mt-12 mb-6 col-12" style="padding: 0px;  border: 2px solid #e10367;" :src="require('/public/Step2.png')"/>
                            <p class="my-6" style="text-align: left;  color: rgb(167, 181, 196)"><b>2. Click the "Get Instagram Place Data" button </b>- After clicking the button, if you are logged out, a new tab will open where you'll be re-directed to the Instagram log in page. Please log in and accept cookies. You'll then be re-directed to your "Place Data". </p>
                            <img class="mt-12 mb-6 col-12" style="padding: 0px; border: 2px solid #e10367;" :src="require('/public/Step3.png')"/>
                            <p class="my-6" style="text-align: left;  color: rgb(167, 181, 196)"><b>3. Copy "Place Data" </b>- Copy all of the text on the new window that appears</p>
                            <img class="mt-12 mb-6 col-12" style="padding: 0px; border: 2px solid #e10367;" :src="require('/public/Step4.png')"/>
                            <p class="my-6 mb-12" style="text-align: left;  color: rgb(167, 181, 196)"><b>4. Paste the "Place Data" in to the text-box.</b> - Once you have copied all the data, paste it into the text box at the bottom of the screen.</p>
                             <img class="mt-12 mb-6 col-12" style="padding: 0px; border: 2px solid #e10367;" :src="require('/public/Step5.png')"/>
                            <p class="my-6 mb-12" style="text-align: left; color: rgb(167, 181, 196) "><b>5. Click the "Map Your Instagram Places" button.</b> Once you have clicked the "Map Your Instagram Places" button your places will be added to the map. You can hover over them and click the "View Posts" button to see the posts tagged to that location.</p>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      <v-expansion-panel style="border: 1px solid #44444410; background-color: #0D1117;" flat>
                          <v-expansion-panel-header style="color: rgb(204, 218, 231);"><b>About InstaHunt</b></v-expansion-panel-header>
                          <v-expansion-panel-content>
                      <v-row>
                            <p class="col-12" style="text-align: start;  color: rgb(167, 181, 196)">✨ If you like this you'll also like: <a style="color: #e10367" href="https://birdhunt.co">BirdHunt.co</a><br>
                            🔨 Made by <a style="color: #e10367" href="https://twitter.com/LouisTomosEvans">@LouisTomosEvans</a></p>
                            </v-row>
                            <v-row class="mt-3">
                            <p class="col-12" style="text-align: end; color: #44444450">InstaHunt V1.0.0</p>
                            </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                        </div>
                    </div>
                </v-card>
            </v-dialog>

            <v-dialog class="advertDialog rounded-4" v-model="huntDialog" max-width="750px">
              <v-row no-gutters>
                <!-- Left side: Image -->
                <v-col cols="5" style="background-color: #0D1117">
                  <div class="w-100 h-100" style="background-image: url('/mapBackground.jpeg'); background-size: cover; background-position: center; position: relative; top: 0; left: 0; z-index: 0; ">
                    <!-- div wih gradient overlay -->
                    <div class="w-100 h-100" style="background: linear-gradient(120deg, rgba(13, 17, 23, 0.80) 0%, rgba(13, 17, 23, 0.99) 100%); position: absolute; top: 0; left: 0; z-index: 1;">
                                        <!-- logo in top right corner -->
                                        <img
                              alt="Hunt Intelligence Logo"
                              :src="require('/public/huntintel.png')"
                              style="max-width: 75px !important; position: relative !important; top: 24px; left: 24px; z-index: 1000;"
                            />
                    </div>

                  </div>
                    <!-- logo in top right corner -->
                    <!-- <v-img
                      alt="Hunt Intelligence Logo"
                      cover
                      :src="require('/public/mapBackground.jpeg')"
                      transition="scale-transition"
                      dense
                      style="min-height: 100%; min-width: 100%; opacity: 0.3;"
                    /> -->
                </v-col>
                
                <!-- Right side: Content -->
                <v-col cols="7">
                  <v-card class="advertDialog p-3 h-100 rounded-0">
                    <v-card-title class="advertDialog rounded-0 p-0">
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeDialog">
                    <v-icon color="#8A97A3">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                    <v-card-text>
                      <div class="headline-title">{{ title }}</div>
                      <div class="subtext">{{ subtext }}</div>
                      <v-btn color="#CCDAE7" class="elevation-0 mt-3 w-100" @click="redirectToSite"><span style="text-decoration: none; text-transform: none; letter-spacing: normal; font-weight: 600;">{{ buttonText }}</span></v-btn>
                      <div class="no-thank-you-container">
                        <div class="no-thank-you mt-1">Or <br><span @click="closeDialog" style="font-weight: bold; cursor: pointer;">No thank you, I'm happy with InstaHunt</span></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-dialog>
    </v-main>
  </v-app>
</template>

<script>

import mapBirdHunt from './components/map.vue';

export default {
  name: 'App',

  components: {
    mapBirdHunt
  }, 

  mounted(){
    // show advert dialog for first time users
    if(localStorage.getItem('firstTime-insta') === null){
      // only show on desktop
      if(window.innerWidth > 767){  
        this.huntDialog = true;
        // localStorage.setItem('firstTime-insta', 'false');
      }

    }
  },

  data(){
    return{
      help: false,
      mapStyle: 'satellite',
      disUnit: 'kilometers',
      dataPresent: false,
      huntDialog: false,
      title: 'Easily find social media posts surrounding a location',
      imageUrl: require('/public/logo1.png'),
      subtext: 'Love InstaHunt? Get access to more features by signing up for a free trial of Hunt Intelligence. Search millions of social media posts from around the world. Get a 7 day free trial now. No credit card required.',
      buttonText: 'Start Free Trial',
    
  }},
  methods: {
    dataSwitch(val){
      this.dataPresent = val;
    },
    closeDialog(){
      this.huntDialog = false;
    },
    redirectToSite(){
      window.open('https://huntintel.io/', '_blank');
    }
  }
};
</script>

<style>

.customDialog.v-dialog--fullscreen {
    width: 41.66%;
    right: 0px;
    left: unset;
    border-radius: 0px;
}

.advertDialog {
  background-color: #0D1117 !important;
  color: #CCDAE7 !important;
}
.headline-title {
  font-weight: 800 !important;
  margin-top: 10px;
  margin-bottom: 2rem;
  font-size: 2rem !important;
  line-height: 2rem !important;
  text-align: center !important;
  /* color as a gradient */
  background: linear-gradient(120deg, #CCDAE7 0%, #8a97a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}
.subtext {
  margin-top: 10px;
  color: #8a97a3;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
}
.no-thank-you {
  margin-top: 10px;
  font-size: 12px;
  color: #8a97a3;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.no-thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
        .padding-mobile {
          padding-left: 0px;
          padding-right: 0px;
        }
        .customDialog.v-dialog--fullscreen {
            width: 100%;
            right: 0px;
            left: 0px;
            border-radius: 0px;
        }
        .noScroll{
            position: fixed;
        }
      }

</style>
